import React, { useState } from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import CommingSoonSection from "../layouts/CommingSoonSection"
import { graphql, useStaticQuery } from "gatsby"
import AgeVerification from "../layouts/AgeVerification/age-verification.component,jsx"
import BackgroundImageSection from "../layouts/BackgroundImageSection/background-image-section.component"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background-mgr.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      founder: file(relativePath: { eq: "man-walking.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      onlyLogo: file(relativePath: { eq: "only-logo-mgr.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      backgroundAV: file(
        relativePath: { eq: "background-age-verification.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      backgroundAV: file(
        relativePath: { eq: "background-age-verification.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      marketBg: file(relativePath: { eq: "market-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  const [verifiedAge, setVerifiedAge] = useState(false)

  const aboutUsDescription =
    `<p>Jordan T. Avery is a cannabis entrepreneur, civil rights activist, community volunteer and organizer.  Born and raised in Lynn, the youngest of 5 siblings. Jordan was instrumental in the approval process for that city’s first medical (RMD) and recreational cannabis dispensaries.  After helping establish that entities’ position in the marketplace Jordan secured his own retail cannabis permit and location. The Massachusetts Cannabis Control Commission “CCC” has issued Massachusetts Green Retail Inc, a provisional license which is scheduled to begin operations first half of 2022. Mr. Avery is an approved Social Equity applicant by the “CCC”. Jordan has plans to announce the next venture in the Cannabis Industry for him later this year. </p>` +
    `<p>As an Associate at Lynnway Associates, a government relations and real estate consulting firm, Jordan consults to clients in the areas of executive management, licensing, compliance, security, public relations, retail development and training.  Jordan has served on many local boards and currently sits on the Human Rights Commission for the city of Lynn. He is a graduate of Lynn public schools and North Shore Community College, as well as the New England Law Enforcement Training Center an accredited police academy by the Municipal Police Training Committee, where he earned the rank of officer. </p>` +
    `<p>To add to his civic duties Jordan officiates basketball and baseball throughout the Commonwealth ranging from youth sports to college athletics. When not on duty Jordan enjoys volunteering for the Salvation Army Disaster Relief Services. Also traveling the world to witness and observe other cultures as part of his lifelong learning experience.</p>`

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />

      <LayoutTitle>AgeVerification</LayoutTitle>
      <AgeVerification
        logoImage={staticQuery.onlyLogo}
        setVerifiedAge={setVerifiedAge}
      />

      <LayoutTitle>CommingSoonSection</LayoutTitle>
      <CommingSoonSection
        backgroundImg={staticQuery.background}
        title="Mass Green Retail Dispensary Opening Soon"
        subtitle="Get notified when we launch"
        founderImage={staticQuery.founder}
        founderName="Jordan T. Avery"
        aboutUsTitle="Who We Are"
        founderDescription="<p>CEO & Founder, Massachusetts Green Retail Inc.</p> <p>President, JTA Associates</p> <p>Associate Senior VP, Lynnway Associates</p> <p>Commisioner, Human Rights Commission Lynn. MA</p>"
        aboutUsDescription={aboutUsDescription}
      />

      <BackgroundImageSection backgroundImg={staticQuery.marketBg} />
    </Layout>
  )
}

export default Layouts
